import React from "react";
import adbnr from "../src/images/adbnr.png";
import blg1 from "../src/images/blgg1.png";
import blg2 from "../src/images/blgg2.png";

import Cms_review from './Cms-review';
import Call_booknow from './Call-booknow';
import Blogbox from './Blogbox';

import Footer from './Footer';
import Pop_routes from './Popular-routes';
import Side_quick from './Side_quick';

const Blogs = () => {


    return (
        <>

            <div className="container">
                <div className="row  py-4">
                    <div className="col-sm-9">
                        <div className="blgdtl_content">
                            <h3 className="sitehdngs">Places to visit explore top 5 places in New Delhi</h3>
                            <div className="blgdtlimg">
                                <img src={blg1} />
                                <span>Fig. 1 New Delhi</span>
                            </div>
                            <div className="table_content">
                                <div class="table_contents">
                                    <h5>In this Article</h5>
                                    <ul>
                                        <li>
                                            <a href="#contents_24961">
                                                About
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#contents_24962">
                                                History of New Delhi
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#contents_24962">
                                                Must visit places in and around New Delhi
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#contents_24962">
                                                Best time of year to visit
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#contents_24962">
                                                Top food joints in New Delhi
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="blg_dtltext">
                                <h6>To use an airport taxi service, you usually need to book in advance and provide information about your flight schedule and pickup location.
                                    You may also be able to book online or through a mobile app.</h6>

                                <p>Airport taxi services are typically offered to commuters who want transportation to and from airports. This services can be particularly useful for
                                    travelers who are unfamiliar with the area or who don't want to deal with the hassle of driving and parking at the airport. When you use an airport
                                    taxi service, a driver will pick you up from your specified location and take you directly to the airport. We also offer pickup services from the
                                    airport and transportation to your final destination.</p>

                                <p>To use an airport taxi service, you usually need to book in advance and provide information about your flight schedule and pickup location.
                                    You may also be able to book online or through a mobile app.</p>

                                <p>
                                    Airport taxi services are typically offered to commuters who want transportation to and from airports. This services can be particularly useful for
                                    travelers who are unfamiliar with the area or who don't want to deal with the hassle of driving and parking at the airport. When you use an airport
                                    taxi service, a driver will pick you up from your specified location and take you directly to the airport. We also offer pickup services from the
                                    airport and transportation to your final destination.  </p>

                                <p>To use an airport taxi service, you usually need to book in advance and provide information about your flight schedule and pickup location. You may also be able to book online or
                                    through a mobile app.
                                </p>
                            </div>
                            <div className="blgdtlimg">
                                <img src={blg2} />
                                <span>Fig. 2 New Delhi</span>
                            </div>
                            <div className="blg_dtltext">
                                <h6>To use an airport taxi service, you usually need to book in advance and provide information about your flight schedule and pickup location.
                                    You may also be able to book online or through a mobile app.</h6>

                                <p>Airport taxi services are typically offered to commuters who want transportation to and from airports. This services can be particularly useful for
                                    travelers who are unfamiliar with the area or who don't want to deal with the hassle of driving and parking at the airport. When you use an airport
                                    taxi service, a driver will pick you up from your specified location and take you directly to the airport. We also offer pickup services from the
                                    airport and transportation to your final destination.</p>

                                <p>To use an airport taxi service, you usually need to book in advance and provide information about your flight schedule and pickup location.
                                    You may also be able to book online or through a mobile app.</p>

                                <p>
                                    Airport taxi services are typically offered to commuters who want transportation to and from airports. This services can be particularly useful for
                                    travelers who are unfamiliar with the area or who don't want to deal with the hassle of driving and parking at the airport. When you use an airport
                                    taxi service, a driver will pick you up from your specified location and take you directly to the airport. We also offer pickup services from the
                                    airport and transportation to your final destination.  </p>

                                <p>To use an airport taxi service, you usually need to book in advance and provide information about your flight schedule and pickup location. You may also be able to book online or
                                    through a mobile app.
                                </p>
                            </div>
                        </div>

                        <div className="bookcabs">
                            <h5>Book Cab from New Delhi</h5>
                        </div>

                        <div className="moreblogs cardlike px-2 mb-4 ">
                            <h5>Explore  More Blogs</h5>
                            <div className="row">
                                <Blogbox />
                                <Blogbox />
                                <Blogbox />
                                <Blogbox />
                                <Blogbox />
                                <Blogbox />
                            </div>
                        </div>

                        <Cms_review />
                        <Call_booknow />
                        <div className="midbnr">
                            <a href="https://play.google.com/store/apps/details?id=com.buzzwaycustomer&hl=en"> <img src={adbnr} alt="imageTag" /></a>
                        </div>
                    </div>
                    <div className="col-sm-3 cmssidebr">
                        <Side_quick />
                    </div>
                </div>
            </div>

            <Pop_routes />
            <Footer />
        </>
    )
}


export default Blogs;
