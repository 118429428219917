import React from "react";
import Blogbox from './Blogbox';
import Connect from './Connect';
import Home_testimonial from './Home-testimonial';
import Footer from './Footer';
import Pop_routes from './Popular-routes';

const Blogs = () => {


    return (
        <>

            <div className="container">
                <div className="row pt-4">
                    <h3 className="sitehdngs">Our Blogs</h3>
                </div>
                <div className="row pb-4">
                    <Blogbox />
                    <Blogbox />
                    <Blogbox />
                    <Blogbox />
                    <Blogbox />
                    <Blogbox />
                    <Blogbox />
                    <Blogbox />
                    <Blogbox />
                </div>
            </div>


            <Connect />
            <Home_testimonial />
            <Pop_routes />
            <Footer />
        </>
    )
}


export default Blogs;
