import React from "react";
import blg1 from "../src/images/blg1.png";

const Blogbox = () => {


    return (
        <>
            <div className="col-sm-4">
                <div className="blogbox">
                    <a href="/blog-details">
                        <div className="blgimg">
                            <img src={blg1} />
                        </div>
                        <div className="blgtxt">
                            <h5>Places to visit explore Top 5 Places in Morbi</h5>
                        </div>
                    </a>
                </div>
            </div>
        </>
    )
}


export default Blogbox;
