import React from "react";
import logo from "../src/images/logo.png";
import fb from "../src/images/facebook.svg";
import insta from "../src/images/insta.svg";
import twitter from "../src/images/twitter.svg";
import yt from "../src/images/yt.svg";
import copyrightline from "../src/images/copyright-line.svg";
import { Link } from "react-router-dom";
import moment from 'moment'
import Config from "./Config/Config";



const Footer = () => {
    return (
        <>
            <footer className="bgblack">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6 logo-email">
                            <img src={logo} />
                            <p>Connect: <a href="">booking@buzzway.in</a></p>
                        </div>
                        <div className="col-sm-6 social">
                            <ul>
                                <li> <a href="https://www.facebook.com/buzzway01/"><img src={fb} /> </a> </li>
                                <li> <a href="https://www.instagram.com/buzzwaytaxiservice/"><img src={insta} /> </a> </li>
                                <li> <a href="https://twitter.com/buzzway01/"><img src={twitter} /> </a> </li>
                                <li> <a href="https://www.youtube.com/channel/UCmk1a_siIybosrgdJrHCcww"><img src={yt} /> </a> </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row footrmenu">
                        <ul>
                            {/* <li><a href="/about-us">About Us</a></li> */}
                            <li><Link to={`/about-us`}>About Us</Link></li>
                            <li><Link to={`/contact-us`}>Contact Us</Link></li>
                            <li><Link to={`/register`}>Drivers</Link></li>
                            <li><Link to={`/terms-conditions`}>Terms & Conditions</Link></li>
                            <li><Link to={`/privacy-policy`}>Privacy Policy</Link></li>
                            <li><Link to={`/refund-Policy`}>Refund Policy</Link></li>
                            <li><a href={`/sitemap.xml`}>Site Map</a></li>
                        </ul>
                    </div>
                    <div className="row">
                        <div className="copyr">
                            <p> <img src={copyrightline} /> Copyright {moment().year()} Duplex Technologies Pvt. Ltd. All Right Reserved.</p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer; 