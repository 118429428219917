import React, { useEffect, useState } from "react";
// import regsbnr from "../src/images/regsbnr.png";
import adbnr from "../src/images/adbnr.png";
import ContactBanner from "../src/images/contact_us_banner.png";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import Download_apps from './Download-apps';

import Home_testimonial from './Home-testimonial';
import Footer from './Footer';
import Pop_routes from './Popular-routes';
import AlertPopupMessage from "./ErrorPopus/AlertPopus";
import { Helmet } from "react-helmet-async";
import ContactUsForm from "./ContactUsForm";
import Config from "./Config/Config";
import axios from 'axios'
import { Card, Col, Container, Row } from "react-bootstrap";
import { FaMapMarkerAlt, FaPhone } from 'react-icons/fa';
import { MdEmail } from "react-icons/md";



const ContactUs = () => {

    const [ContactUs, setContactUs] = useState({});
    const [metaData, setMetaData] = useState({
        meta_title: '',
        meta_description: '',
        meta_keyword: ''
    });


    const handleContactUsAPI = async () => {
        try {
            let Payloads = {
                page_type: 'contact-us'
            }
            let response = await axios.post(`${Config.API_URL}/api/customer/common/cms/list`, Payloads);
            if (response.data.status) {
                setContactUs(response.data.data[0]);
            } else {
                setContactUs([]);
            }
        } catch (error) {
            setContactUs([]);

        }
    }

    // here handle the Contact us page
    useEffect(() => {
        handleContactUsAPI();
    }, [])

    // handle the meta description & title here
    useEffect(() => {
        setMetaData({
            meta_title: ContactUs.meta_title,
            meta_description: ContactUs.meta_description,
            meta_keyword: ContactUs.meta_keyword
        });
    }, [ContactUs.meta_description, ContactUs.meta_keyword, ContactUs.meta_title])

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.scrollTo(0, 0);
        }
    }, []);

    return (
        <>
            <AlertPopupMessage />
            <Helmet>
                <title>{metaData.meta_title}</title>
                <meta name="description" content={metaData.meta_description} />
                <meta name="keywords" content={metaData.meta_keyword} />
            </Helmet>
            <div className="fullbnr">
                <img src={ContactBanner} alt="Banner imag" />
                <div className="ovrlylefttext ovrlytext">
                    <h5> Contact Us </h5>
                </div>
            </div>
            <div className="container">
                <div className="row py-5">
                    <div className="col-sm-12">
                        <h3 className='text-align-content-center'>{ContactUs.h_one_tag}</h3>
                        <div className="abttxt" dangerouslySetInnerHTML={{ __html: Object.entries(ContactUs).length > 0 && ContactUs.content_data }} />
                        <div className="row">
                            <h3 className="text-center mt-3">Contact Us</h3>
                            {/* <ContactUsForm /> */}
                            <Row>
                                <Col md={6} data-aos="fade-up">
                                    <Card className="my-3 text-bg-light">
                                        <Card.Body className="text-center text-bg-light">

                                            <div className="icon-circle">
                                                <MdEmail />
                                                <p className="info-title">
                                                    <a href="mailto:Buzzway@gmail.com" className="text-decoration-none text-dark">
                                                        Buzzway@gmail.com
                                                    </a>
                                                </p>
                                            </div>

                                            <div className="icon-circle">
                                                <FaPhone />
                                                <p className="info-title">
                                                    <a href="tel:+1234567890" className="text-decoration-none text-dark">
                                                        +1 234 567 890
                                                    </a>
                                                </p>
                                            </div>

                                            <div className="icon-circle">
                                                <FaMapMarkerAlt />
                                                <p className="info-title text-dark">123 Main Street, Cityville</p>
                                            </div>

                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={6} data-aos="fade-up">
                                    <Card className="my-3 text-bg-light">
                                        <Card.Body className="text-center text-bg-light">

                                            <div className="icon-circle">
                                                <MdEmail />
                                                <p className="info-title">
                                                    <a href="mailto:Buzzway@gmail.com" className="text-decoration-none text-dark">
                                                        Buzzway@gmail.com
                                                    </a>
                                                </p>
                                            </div>

                                            <div className="icon-circle">
                                                <FaPhone />
                                                <p className="info-title">
                                                    <a href="tel:+1234567890" className="text-decoration-none text-dark">
                                                        +1 234 567 890
                                                    </a>
                                                </p>
                                            </div>

                                            <div className="icon-circle">
                                                <FaMapMarkerAlt />
                                                <p className="info-title text-dark">123 Main Street, Cityville</p>
                                            </div>

                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={6} data-aos="fade-up">
                                    <Card className="my-3 text-bg-light">
                                        <Card.Body className="text-center text-bg-light">

                                            <div className="icon-circle">
                                                <MdEmail />
                                                <p className="info-title">
                                                    <a href="mailto:Buzzway@gmail.com" className="text-decoration-none text-dark">
                                                        Buzzway@gmail.com
                                                    </a>
                                                </p>
                                            </div>

                                            <div className="icon-circle">
                                                <FaPhone />
                                                <p className="info-title">
                                                    <a href="tel:+1234567890" className="text-decoration-none text-dark">
                                                        +1 234 567 890
                                                    </a>
                                                </p>
                                            </div>

                                            <div className="icon-circle">
                                                <FaMapMarkerAlt />
                                                <p className="info-title text-dark">123 Main Street, Cityville</p>
                                            </div>

                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={6} data-aos="fade-up">
                                    <Card className="my-3 text-bg-light">
                                        <Card.Body className="text-center text-bg-light">

                                            <div className="icon-circle">
                                                <MdEmail />
                                                <p className="info-title">
                                                    <a href="mailto:Buzzway@gmail.com" className="text-decoration-none text-dark">
                                                        Buzzway@gmail.com
                                                    </a>
                                                </p>
                                            </div>

                                            <div className="icon-circle">
                                                <FaPhone />
                                                <p className="info-title">
                                                    <a href="tel:+1234567890" className="text-decoration-none text-dark">
                                                        +1 234 567 890
                                                    </a>
                                                </p>
                                            </div>

                                            <div className="icon-circle">
                                                <FaMapMarkerAlt />
                                                <p className="info-title text-dark">123 Main Street, Cityville</p>
                                            </div>

                                        </Card.Body>
                                    </Card>
                                </Col>

                            </Row>
                        </div>
                    </div>
                </div>
            </div>

            <Home_testimonial />
            <Download_apps />

            <Pop_routes />

            <Footer />
        </>
    )
}


export default ContactUs;
